<app-header
  (showLoader)="onShowLoader($event)"
  
></app-header>
<app-carousel></app-carousel>

<section class="path" *ngIf="!showLoader">
  <div class="wrapper">
    <div class="path__row">
      <a routerLink="/" class="path__link">{{ "Home.home" | translate }}</a>
      <span class="path__sep">/</span>
      <a
        routerLink="/{{ filtered_website_language }}/home/{{ productType }}/{{
          cityName
        }}/{{ countryISO }}/{{ cPage }}"
        class="path__link"
        >{{ "Home.adventure" | translate }}: {{ cityNameTranslated }},
        {{ countryName }}</a
      >
      <span class="path__sep">/</span>
      <p class="path__crnt">{{ nameForTour }}</p>
    </div>
  </div>
</section>

<div *ngIf="!isTripster">
  <section class="ex" *ngIf="!showLoader">
    <div class="wrapper">
      <div class="ex__head">
        <h2 class="ex__title section-title">{{ nameForTour }}</h2>
        <p class="ex__subtitle">{{ tourAddress }}</p>

        <a (click)="backToSearchResults()" class="ex__back">
          <img
            src="../../assets/img/icons/back-arrow.png"
            alt=""
            width="16"
            height="16"
          />
          {{ "Product.backToSearchResults" | translate }}
        </a>
      </div>
      <div class="ex__content">
        <div class="ex__right">
          <div class="main-image">
            <img
              src="{{ currentImage }}"
              (click)="selectHotelImage(0)"
              alt="hotel"
            />
          </div>
        </div>

        <div class="carousel-container ex__images">
          <div class="thumbnail-images-container">
            <div class="thumbnail-images">
              <div
                *ngFor="let image of imageURLs; index as i"
                (click)="selectHotelImage(i)"
              >
                <div class="image-container">
                  <img *ngIf="i < 6" src="{{ image }}" alt="hotel" />
                  <div class="overlay">
                    <a class="icon">
                      <i class="fa fa-search-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ex__content" *ngIf="!isTiqets">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="fromDate"
            [min]="minDateFrom"
            placeholder="Start Date"
            [(ngModel)]="startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #fromDate
            (opened)="checkTourAvailability()"
            (closed)="checkTourAvailability()"
          ></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="ex__content" *ngIf="!isTiqets">
        <mat-form-field>
          <mat-label>{{ "Paddhotel.guests" | translate }}</mat-label>
          <mat-select [(ngModel)]="guests">
            <mat-option *ngFor="let num of guestsRange" [value]="num">{{
              num
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="ex__content" *ngIf="!isTiqets">
        <form
          id="book-button"
          action="https://admin.nobitour.com/booking/addToNobiCart"
          method="POST"
          style="display: none"
          target="_blank"
        >
          <input type="hidden" name="service_id" value="{{ service_id }}" />
          <input type="hidden" name="service_type" value="tour" />
          <input
            type="hidden"
            name="start_date"
            value="{{ updatedFormStartDate }}"
          />
          <input type="hidden" name="person_types" value="" />

          <input type="hidden" name="guests" value="{{ guests }}" />

          <input type="hidden" name="_token" value="{{ token }}" />
        </form>

        <a
          *ngIf="is_verified"
          class="ex__btn"
          onclick="event.preventDefault(); document.getElementById('book-button').submit();"
        >
          {{ "Product.booking" | translate }}</a
        >
        <a
          *ngIf="!is_verified"
          class="ex__btn"
          (click)="saveCurrentUrl()"
          href="https://admin.nobitour.com/{{
            filtered_website_language
          }}/login"
        >
          {{ "Product.booking" | translate }}</a
        >
      </div>
      <a *ngIf="isTiqets" (click)="redirectToTiqets()" class="ex__btn">{{
        "Product.booking" | translate
      }}</a>

      <div *ngIf="showRating" class="ex__top border-row">
        <div class="ex__rating">
          <span class="ex__char">{{ "Product.rating" | translate }}</span>
          <span class="ex__like">
            {{ adventure.rating }}
            <img
              src="../../assets/img/icons/like.png"
              alt=""
              width="24"
              height="24"
            />
          </span>
        </div>
        <a
          (click)="openTiqetsWhitelabel('adventureRu')"
          class="ex__widget"
          style="cursor: pointer"
        >
          <img
            src="../../assets/img/icons/widget.png"
            alt=""
            width="58"
            height="58"
          />
          <span>{{ "Home.iframeRussia6" | translate }}</span>
        </a>
      </div>
      <p *ngIf="isTiqets" class="ex__cost">
        {{ "Home.price" | translate }}:
        <span>
          {{ stripDecimals(advprice) }} ₽
        </span>
      </p>

      <p *ngIf="showConvertedPrice && isTiqets" class="ex__cost">
        {{ "Home.price" | translate }}:
        <span>
          {{ advprice * conversion_rate | roundUp }} {{ default_curr }}
        </span>
      </p>




      <div *ngIf="showInclusion" class="ex__item">
        <p class="ex__char">{{ "PAdventures.inclusions" | translate }}</p>
        <p class="ex__val">{{ inclusion }}</p>
      </div>
      <div *ngIf="showExclusion" class="ex__item">
        <p class="ex__char">{{ "PAdventures.exclusions" | translate }}</p>
        <p class="ex__val">{{ exclusion }}</p>
      </div>
      <div *ngIf="showDeparture" class="ex__item">
        <p class="ex__char">{{ "PAdventures.departure" | translate }}</p>
        <p class="ex__val">{{ itinerary }}</p>
      </div>
      <div *ngIf="showItinerary" class="ex__item">
        <p class="ex__char">{{ "PAdventures.itinerary" | translate }}</p>
        <p class="ex__val">{{ adventure.itinerary }}</p>
      </div>

      <div *ngIf="isTiqets" class="ex__row">
        <h2 class="ex-title">{{ "Product.description" | translate }}</h2>
        <p class="ex__val">{{ description }}</p>
      </div>

      <div *ngIf="!isTiqets" class="ex__row">
        <h2 class="ex-title">{{ "Product.description" | translate }}</h2>
        <div
          class="desc_content"
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)"
        ></div>
      </div>

      <div *ngIf="!isTiqets && showHighlights" class="ex__row">
        <h2 class="ex-title">{{ "Product.highlights" | translate }}</h2>
        <ul class="highlights" *ngFor="let highlight of highlights">
          <li>{{ highlight }}</li>
        </ul>
      </div>

      <div *ngIf="!isTiqets" class="price_row">
        <div class="col-md-6 mb-4" *ngFor="let inclusion of price_includes">
          <div class="font-weight-bold text-dark mb-3">
            {{ "Product.priceIncludes" | translate }}
          </div>
          <div class="flex-horizontal-center mb-3 text-gray-1">
            <mat-icon class="check_icon">check</mat-icon>
            {{ inclusion }}
          </div>
        </div>
        <div class="col-md-6 mb-4" *ngFor="let exclusion of price_excludes">
          <div class="font-weight-bold text-dark mb-3">
            {{ "Product.priceExcludes" | translate }}
          </div>
          <div class="flex-horizontal-center mb-3 text-gray-1">
            <mat-icon class="check_icon">check</mat-icon>
            {{ exclusion }}
          </div>
        </div>
      </div>

      <div *ngIf="!isTiqets && showTravelStyles" class="ex__row">
        <h2 class="ex-title">{{ "Product.travelStyles" | translate }}</h2>
        <ul class="row">
          <li *ngFor="let travel_style of travel_styles">
            <mat-icon class="check_icon">check_circle</mat-icon
            >{{ travel_style }}
          </li>
        </ul>
      </div>

      <div *ngIf="!isTiqets && showFacilities" class="ex__row">
        <h2 class="ex-title">{{ "Product.facilities" | translate }}</h2>
        <ul class="row">
          <li *ngFor="let facility of facilities">
            <mat-icon class="check_icon">check_circle</mat-icon>{{ facility }}
          </li>
        </ul>
      </div>

      <div *ngIf="showLocation" class="ex__row">
        <h2 class="ex-title">{{ "Product.location" | translate }}</h2>
        <span>
          <p>
            <mat-divider style="width: 80%"></mat-divider>
          </p>
          <div fxLayoutAlign="center center" style="width: 100%">
            <iframe
              *ngIf="!isTiqets"
              width="100%"
              height="450"
              frameborder="0"
              style="border: 0"
              loading="lazy"
              allowfullscreen
              aria-hidden="false"
              [src]="googlePlaceUrl | safe"
            ></iframe>

            <iframe
              *ngIf="isTiqets"
              width="100%"
              height="450"
              frameborder="0"
              style="border: 0"
              loading="lazy"
              allowfullscreen
              aria-hidden="false"
              [src]="
                'https://maps.google.com/maps?q=' +
                  adventure.googleCoordinates +
                  '&hl=es;z=14&amp;output=embed' | safe
              "
            ></iframe>
          </div>
        </span>
      </div>
    </div>
  </section>

  <section class="descr" *ngIf="!showLoader">
    <div class="wrapper">
      <h2 class="ex-title"></h2>
    </div>
  </section>

  <section class="city" *ngIf="!showLoader">
    <h2 *ngIf="recommendedCities.length > 0" class="city__title section-title">
      {{ "Home.recommendedAdventuresIntro" | translate }}
    </h2>
    <div class="wrapper">
      <div class="city__row">
        <div class="city__items">
          <div
            class="city__item sm-card recommend"
            *ngFor="let city of recommendedCities; index as i"
          >
            <a style="display: none" routerLink="{{ city.url }}"></a>
            <a
              class="recommend"
              (click)="
                navigateToRecommendedCity(
                  city.city.toLowerCase(),
                  city.country_iso.toLowerCase()
                )
              "
              class="sm-card__name"
            >
              <div class="sm-card__img">
                <img src="{{ city.photo1 }}" alt="" width="549" height="366" />
              </div>
              <div class="sm-card__row">
                {{ city.city }}
                <img
                  src="{{
                    '../../assets/img/countries/48/' +
                      city.country_iso.toUpperCase() +
                      '.png'
                  }}"
                  alt=""
                  width="60"
                  height="33"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="isTripster">
  <!-- <div class="ex__content">
        <div class="ex__right">
          <div class="main-image">
            <img
              src="{{ currentImage }}"
              (click)="selectHotelImage(0)"
              alt="hotel"
            />
          </div>
        </div>

        <div class="carousel-container ex__images">
          <div class="thumbnail-images-container">
            <div class="thumbnail-images">
              <div
                *ngFor="let image of tripsterImages; index as i"
                (click)="selectHotelImage(i)"
              >
                <div class="image-container">
                  <img *ngIf="i < 6" src="{{ image.medium }}" alt="hotel" />
                  <div class="overlay">
                    <a class="icon">
                      <i class="fa fa-search-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
  <!-- <p>{{nameForTour}}</p>
    <p>{{cityName}}</p>
    <p>{{countryName}}</p>
    <p    
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(description)"
    ></p>
    <p>{{display_price}}</p>
    <p>{{duration}} days</p>
    <p>{{tripsterProductType}}</p>
    <p>{{tripster_adventure[0].tagline}}</p>
    <p>{{starRating}} Stars</p> -->
  <!-- <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p>
    <p>{{tripster_adventure[0].name}}</p> -->
</div>
<div *ngIf="isTripster">
  <section class="ex" *ngIf="!showLoader">
    <div class="wrapper">
      <div class="ex__head">
        <h2 class="ex__title section-title">{{ nameForTour | nameTranslator |async }}</h2>
        <!-- <p class="ex__subtitle">{{ tourAddress }}</p> -->

        <a (click)="backToSearchResults()" class="ex__back">
          <img
            src="../../assets/img/icons/back-arrow.png"
            alt=""
            width="16"
            height="16"
          />
          {{ "Product.backToSearchResults" | translate }}
        </a>
      </div>
      <div class="ex__content">
        <div class="ex__right">
          <div class="main-image">
            <img
              src="{{ currentImage }}"
              (click)="selectHotelImage(0)"
              alt="hotel"
            />
          </div>
        </div>

        <div class="carousel-container ex__images">
          <div class="thumbnail-images-container">
            <div class="thumbnail-images">
              <div
                *ngFor="let image of imageURLs; index as i"
                (click)="selectHotelImage(i)"
              >
                <div class="image-container">
                  <img *ngIf="i < 6" src="{{ image.medium }}" alt="hotel" />
                  <div class="overlay">
                    <a class="icon">
                      <i class="fa fa-search-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a (click)="redirectToTripster()" class="ex__btn">{{
        "Product.booking" | translate
      }}</a>

      <div class="ex__top border-row">
        <div class="ex__rating">
          <span class="ex__char">{{ "Product.rating" | translate }}</span>
          <span class="ex__like">
            {{ starRating }}
            <img
              src="../../assets/img/icons/like.png"
              alt=""
              width="24"
              height="24"
            />
          </span>
        </div>

      </div>
      <p class="ex__cost">
        {{ "Home.price" | translate }}:
        <span
          >{{ display_price  }}
          {{ default_curr }}</span
        >
      </p>

      <div class="ex__row">
        <h2 class="ex-title">{{ "Product.description" | translate }}</h2>
        <div
          class="desc_content"
        ></div> {{description | nameTranslator |async}}
      </div>

      <!-- <div class="ex__row">
        <div class="tag-grid-container">
          <div *ngFor="let tag of tag_ids; index as i" class="tag_ex__item">
            <p class="tag_ex__char">{{ tag.name | nameTranslator |async }}</p>
          </div>
        </div>
      </div> -->
      
    </div>
  </section>

  <section class="descr" *ngIf="!showLoader">
    <div class="wrapper">
      <h2 class="ex-title"></h2>
    </div>
  </section>

  <section class="city" *ngIf="!showLoader">
    <h2 *ngIf="recommendedCities.length > 0" class="city__title section-title">
      {{ "Home.recommendedAdventuresIntro" | translate }}
    </h2>
    <div class="wrapper">
      <div class="city__row">
        <div class="city__items">
          <div
            class="city__item sm-card recommend"
            *ngFor="let city of recommendedCities; index as i"
          >
            <a style="display: none" routerLink="{{ city.url }}"></a>
            <a
              class="recommend"
              (click)="
                navigateToRecommendedCity(
                  city.city.toLowerCase(),
                  city.country_iso.toLowerCase()
                )
              "
              class="sm-card__name"
            >
              <div class="sm-card__img">
                <img src="{{ city.photo1 }}" alt="" width="549" height="366" />
              </div>
              <div class="sm-card__row">
                {{ city.city }}
                <img
                  src="{{
                    '../../assets/img/countries/48/' +
                      city.country_iso.toUpperCase() +
                      '.png'
                  }}"
                  alt=""
                  width="60"
                  height="33"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="basic-container" *ngIf="showLoader">
  <div class="spinner-position">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
</div>

<app-bottom *ngIf="!showLoader"></app-bottom>
